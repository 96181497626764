import { default as indexwVKccFmgv0Meta } from "/var/www/insylio/front/pages/calendar/index.vue?macro=true";
import { default as indexrKriXMmLvEMeta } from "/var/www/insylio/front/pages/calendar/month/index.vue?macro=true";
import { default as indexdOLReaJvYDMeta } from "/var/www/insylio/front/pages/calendar/today/index.vue?macro=true";
import { default as indexELHHPEoFr7Meta } from "/var/www/insylio/front/pages/calendar/week/index.vue?macro=true";
import { default as _91hash_93FrhC2Qx9jiMeta } from "/var/www/insylio/front/pages/chat/[hash].vue?macro=true";
import { default as chatUw5xV2oB1XMeta } from "/var/www/insylio/front/pages/chat.vue?macro=true";
import { default as indexSCgEd47dbLMeta } from "/var/www/insylio/front/pages/employees/index.vue?macro=true";
import { default as indexhjoe9dbPfXMeta } from "/var/www/insylio/front/pages/forms/index.vue?macro=true";
import { default as indexUARINYU3yZMeta } from "/var/www/insylio/front/pages/index.vue?macro=true";
import { default as indexubkK4XNBrLMeta } from "/var/www/insylio/front/pages/login/index.vue?macro=true";
import { default as indexcmjuw3WQcbMeta } from "/var/www/insylio/front/pages/medicines/index.vue?macro=true";
import { default as indexcUDEcWevswMeta } from "/var/www/insylio/front/pages/notifications/index.vue?macro=true";
import { default as indexdACvAfYHsmMeta } from "/var/www/insylio/front/pages/patients/index.vue?macro=true";
import { default as index8GSD8jkocHMeta } from "/var/www/insylio/front/pages/register/index.vue?macro=true";
import { default as indexftqV2lMaBUMeta } from "/var/www/insylio/front/pages/remind-password/index.vue?macro=true";
import { default as index9gbu3rYveyMeta } from "/var/www/insylio/front/pages/reports/costs-estimate/index.vue?macro=true";
import { default as index3ruMwLVsyOMeta } from "/var/www/insylio/front/pages/reports/index.vue?macro=true";
import { default as index10tR5kTcikMeta } from "/var/www/insylio/front/pages/reports/other-reports/index.vue?macro=true";
import { default as indexffncGr2T0hMeta } from "/var/www/insylio/front/pages/reset-password/index.vue?macro=true";
import { default as indexCsyZDZRnbkMeta } from "/var/www/insylio/front/pages/schemats/index.vue?macro=true";
import { default as index8ItgbAnPfqMeta } from "/var/www/insylio/front/pages/surveys/index.vue?macro=true";
import { default as indexCXjoC0FblXMeta } from "/var/www/insylio/front/pages/symptoms/index.vue?macro=true";
import { default as index9ImZsgAqt8Meta } from "/var/www/insylio/front/pages/verify-code/index.vue?macro=true";
export default [
  {
    name: "calendar",
    path: "/calendar",
    meta: indexwVKccFmgv0Meta || {},
    component: () => import("/var/www/insylio/front/pages/calendar/index.vue").then(m => m.default || m)
  },
  {
    name: "calendar-month",
    path: "/calendar/month",
    meta: indexrKriXMmLvEMeta || {},
    component: () => import("/var/www/insylio/front/pages/calendar/month/index.vue").then(m => m.default || m)
  },
  {
    name: "calendar-today",
    path: "/calendar/today",
    meta: indexdOLReaJvYDMeta || {},
    component: () => import("/var/www/insylio/front/pages/calendar/today/index.vue").then(m => m.default || m)
  },
  {
    name: "calendar-week",
    path: "/calendar/week",
    meta: indexELHHPEoFr7Meta || {},
    component: () => import("/var/www/insylio/front/pages/calendar/week/index.vue").then(m => m.default || m)
  },
  {
    name: "chat",
    path: "/chat",
    meta: chatUw5xV2oB1XMeta || {},
    component: () => import("/var/www/insylio/front/pages/chat.vue").then(m => m.default || m),
    children: [
  {
    name: "chat-hash",
    path: ":hash()",
    component: () => import("/var/www/insylio/front/pages/chat/[hash].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "employees",
    path: "/employees",
    meta: indexSCgEd47dbLMeta || {},
    component: () => import("/var/www/insylio/front/pages/employees/index.vue").then(m => m.default || m)
  },
  {
    name: "forms",
    path: "/forms",
    meta: indexhjoe9dbPfXMeta || {},
    component: () => import("/var/www/insylio/front/pages/forms/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexUARINYU3yZMeta || {},
    component: () => import("/var/www/insylio/front/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: indexubkK4XNBrLMeta || {},
    component: () => import("/var/www/insylio/front/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: "medicines",
    path: "/medicines",
    meta: indexcmjuw3WQcbMeta || {},
    component: () => import("/var/www/insylio/front/pages/medicines/index.vue").then(m => m.default || m)
  },
  {
    name: "notifications",
    path: "/notifications",
    meta: indexcUDEcWevswMeta || {},
    component: () => import("/var/www/insylio/front/pages/notifications/index.vue").then(m => m.default || m)
  },
  {
    name: "patients",
    path: "/patients",
    meta: indexdACvAfYHsmMeta || {},
    component: () => import("/var/www/insylio/front/pages/patients/index.vue").then(m => m.default || m)
  },
  {
    name: "register",
    path: "/register",
    meta: index8GSD8jkocHMeta || {},
    component: () => import("/var/www/insylio/front/pages/register/index.vue").then(m => m.default || m)
  },
  {
    name: "remind-password",
    path: "/remind-password",
    meta: indexftqV2lMaBUMeta || {},
    component: () => import("/var/www/insylio/front/pages/remind-password/index.vue").then(m => m.default || m)
  },
  {
    name: "reports-costs-estimate",
    path: "/reports/costs-estimate",
    meta: index9gbu3rYveyMeta || {},
    component: () => import("/var/www/insylio/front/pages/reports/costs-estimate/index.vue").then(m => m.default || m)
  },
  {
    name: "reports",
    path: "/reports",
    meta: index3ruMwLVsyOMeta || {},
    component: () => import("/var/www/insylio/front/pages/reports/index.vue").then(m => m.default || m)
  },
  {
    name: "reports-other-reports",
    path: "/reports/other-reports",
    meta: index10tR5kTcikMeta || {},
    component: () => import("/var/www/insylio/front/pages/reports/other-reports/index.vue").then(m => m.default || m)
  },
  {
    name: "reset-password",
    path: "/reset-password",
    meta: indexffncGr2T0hMeta || {},
    component: () => import("/var/www/insylio/front/pages/reset-password/index.vue").then(m => m.default || m)
  },
  {
    name: "schemats",
    path: "/schemats",
    meta: indexCsyZDZRnbkMeta || {},
    component: () => import("/var/www/insylio/front/pages/schemats/index.vue").then(m => m.default || m)
  },
  {
    name: "surveys",
    path: "/surveys",
    meta: index8ItgbAnPfqMeta || {},
    component: () => import("/var/www/insylio/front/pages/surveys/index.vue").then(m => m.default || m)
  },
  {
    name: "symptoms",
    path: "/symptoms",
    meta: indexCXjoC0FblXMeta || {},
    component: () => import("/var/www/insylio/front/pages/symptoms/index.vue").then(m => m.default || m)
  },
  {
    name: "verify-code",
    path: "/verify-code",
    meta: index9ImZsgAqt8Meta || {},
    component: () => import("/var/www/insylio/front/pages/verify-code/index.vue").then(m => m.default || m)
  }
]